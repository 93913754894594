import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "q-pa-md" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TurmaForm = _resolveComponent("TurmaForm")!
  const _component_AddAlunoForm = _resolveComponent("AddAlunoForm")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_space = _resolveComponent("q-space")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_badge = _resolveComponent("q-badge")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.planos && _ctx.planos.length)
      ? (_openBlock(), _createBlock(_component_TurmaForm, {
          key: 0,
          planos: _ctx.planos,
          onUpdate: _ctx.list,
          ref: "modalForm"
        }, null, 8, ["planos", "onUpdate"]))
      : _createCommentVNode("", true),
    _createVNode(_component_AddAlunoForm, {
      onUpdate: _ctx.list,
      ref: "modalAddAlunoForm"
    }, null, 8, ["onUpdate"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_q_table, {
        title: "Turmas",
        rows: _ctx.rows,
        columns: _ctx.columns,
        "row-key": "id",
        filter: _ctx.filter,
        loading: _ctx.loading,
        pagination: { rowsPerPage: 10 }
      }, {
        top: _withCtx(() => [
          _createVNode(_component_q_btn, {
            label: "Nova Turma",
            color: "primary",
            icon: "add",
            onClick: _ctx.openModalForm,
            disable: !(_ctx.planos && _ctx.planos.length)
          }, null, 8, ["onClick", "disable"]),
          _createVNode(_component_q_space),
          _createVNode(_component_q_input, {
            dense: "",
            debounce: "300",
            color: "primary",
            modelValue: _ctx.filter,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_q_icon, { name: "search" })
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        "body-cell-created_at": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.formatData(props.value.toString())), 1)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-status": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              (props.value)
                ? (_openBlock(), _createBlock(_component_q_badge, {
                    key: 0,
                    color: "green",
                    label: "ATIVO"
                  }))
                : (_openBlock(), _createBlock(_component_q_badge, {
                    key: 1,
                    color: "negative",
                    label: "INATIVO"
                  }))
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        "body-cell-actions": _withCtx((props) => [
          _createVNode(_component_q_td, { props: props }, {
            default: _withCtx(() => [
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "primary",
                "icon-right": "edit",
                onClick: ($event: any) => (_ctx.openModalForm(props.row)),
                disable: !(_ctx.planos && _ctx.planos.length) || _ctx.loading
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Editar ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["onClick", "disable"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "green",
                "icon-right": "group_add",
                disable: _ctx.loading,
                onClick: ($event: any) => (_ctx.openAddAlunoModalForm(props.row))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Alunos ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "red",
                "icon-right": "delete",
                disable: _ctx.loading,
                onClick: ($event: any) => (_ctx.confirmDelete(props.row.id, props.row.descricao))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Excluir ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["disable", "onClick"]),
              _createVNode(_component_q_btn, {
                "no-caps": "",
                flat: "",
                dense: "",
                color: "primary",
                "icon-right": "history_edu",
                disable: _ctx.loading,
                to: '/turmas-avaliacoes/' + props.row.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_tooltip, null, {
                    default: _withCtx(() => [
                      _createTextVNode(" Visualizar Avaliações ")
                    ]),
                    _: 1
                  })
                ]),
                _: 2
              }, 1032, ["disable", "to"]),
              (props.row.status)
                ? (_openBlock(), _createBlock(_component_q_btn, {
                    key: 0,
                    "no-caps": "",
                    flat: "",
                    dense: "",
                    color: "red",
                    "icon-right": "block",
                    disable: _ctx.loading,
                    onClick: ($event: any) => (_ctx.changeStatus(props.row))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_tooltip, null, {
                        default: _withCtx(() => [
                          _createTextVNode(" Desativar ")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 2
                  }, 1032, ["disable", "onClick"]))
                : (!props.row.status)
                  ? (_openBlock(), _createBlock(_component_q_btn, {
                      key: 1,
                      "no-caps": "",
                      flat: "",
                      dense: "",
                      color: "green",
                      "icon-right": "check",
                      disable: _ctx.loading,
                      onClick: ($event: any) => (_ctx.changeStatus(props.row))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_q_tooltip, null, {
                          default: _withCtx(() => [
                            _createTextVNode(" Ativar ")
                          ]),
                          _: 1
                        })
                      ]),
                      _: 2
                    }, 1032, ["disable", "onClick"]))
                  : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1032, ["props"])
        ]),
        _: 1
      }, 8, ["rows", "columns", "filter", "loading"])
    ])
  ]))
}